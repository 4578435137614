import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import Plans                from "Utils/Plans";
import { Subscription }     from "Utils/API";



// The initial State
const initialState = {
    loading       : true,
    error         : false,
    edition       : 0,
    canCreate     : false,
    canEdit       : false,
    canImport     : false,
    canExport     : false,
    canFilter     : false,
    list          : [],
    total         : 0,
    elem          : {
        addons     : [],
        wabas      : [],
        sms        : [],
        assistants : [],
    },
    stats         : {
        planName       : "",
        amountText     : "",
        basePrice      : "",
        addonPrice     : "",
        connectorPrice : "",
        totalPrice     : "",
    },
    partners      : [],
    clients       : [],
    idsPerPartner : {},
    filters       : {},
    hasFilters    : false,
    sort          : {
        orderBy  : "createdTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "SUBSCRIPTION_LOADING" });
    },

    /**
     * Fetches all the Subscription
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        if (type === "PARTNER") {
            params.partnerID = elemID;
        } else if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data    = await Subscription.getAll(params);
        data.forAdmin = type === "ADMIN";
        data.filters  = filters;
        data.sort     = sort;
        dispatch({ type : "SUBSCRIPTION_LIST", data });
    },

    /**
     * Fetches a single Subscription
     * @param {Function} dispatch
     * @param {Number}   subscriptionID
     * @param {Boolean}  forAdmin
     * @returns {Promise}
     */
    async fetchElem(dispatch, subscriptionID, forAdmin) {
        const data = await Subscription.getOne({ subscriptionID });
        data.forAdmin = forAdmin;
        dispatch({ type : "SUBSCRIPTION_ELEM", data });
    },

    /**
     * Fetches the Subscription Filter data
     * @param {Function} dispatch
     * @param {Number}   partnerID
     * @returns {Promise}
     */
    async fetchFilterData(dispatch, partnerID) {
        const data = await Subscription.getFilterData({ partnerID });
        dispatch({ type : "SUBSCRIPTION_FILTER", data });
    },

    /**
     * Deletes a Subscription
     * @param {Function} dispatch
     * @param {Number}   subscriptionID
     * @returns {Promise}
     */
    deleteSubscription(dispatch, subscriptionID) {
        return Subscription.delete({ subscriptionID });
    },
};



/**
 * Parses a single Element
 * @param {Object}  elem
 * @param {Boolean} forAdmin
 * @returns {Object}
 */
function parseElem(elem, forAdmin) {
    elem.createdPeriod    = DateTime.formatDate(elem.createdTime, "monthYear");
    elem.createdDate      = DateTime.formatDate(elem.createdTime, "dashes");

    let   totalPrice      = Number(elem.totalPrice);
    let   planPrice       = Number(elem.planPrice);
    let   roomPrice       = Number(elem.roomPrice);
    let   contactPrice    = Number(elem.contactPrice);
    let   addonPrice      = 0;
    let   connectorPrice  = 0;
    let   discountPrice   = 0;
    let   daysPrice       = 0;

    const addons          = [];
    const connectors      = [];
    const currencySymbol  = elem.currencySymbol;
    const hasCommission   = forAdmin && elem.commission;
    const commission      = hasCommission ? (100 - Math.min(100, Number(elem.commission))) / 100 : 0;

    // Calculate the addons prices
    for (const { addonCode, name, quantity, price } of elem.addons) {
        const amount     = quantity || 1;
        const finalPrice = price * commission;
        addonPrice      += amount * finalPrice;

        addons.push({
            key      : addonCode,
            name     : NLS.format("SUBSCRIPTIONS_ADDON", name),
            quantity : amount,
            price    : Commons.formatPrice(finalPrice, elem.currencySymbol),
            total    : Commons.formatPrice(amount * finalPrice, elem.currencySymbol),
        });
    }

    // Calculate the connectors prices
    for (const { connectorCode, name, quantity, price } of elem.connectors) {
        const amount     = quantity || 1;
        const finalPrice = price * commission;
        connectorPrice  += amount * finalPrice;

        connectors.push({
            key      : connectorCode,
            name     : NLS.format("SUBSCRIPTIONS_CONNECTOR", name),
            quantity : amount,
            price    : Commons.formatPrice(finalPrice, elem.currencySymbol),
            total    : Commons.formatPrice(amount * finalPrice, elem.currencySymbol),
        });
    }

    // Calculate the total price
    const roomTotal = roomPrice * elem.roomAmount;
    const total     = planPrice + roomTotal + contactPrice + addonPrice + connectorPrice;

    // Apply the discount
    if (elem.discount) {
        elem.discountText = NLS.format("SUBSCRIPTIONS_CLIENT_DISCOUNT", elem.discount);

        const discount = (100 - Math.min(100, Number(elem.discount))) / 100;
        discountPrice  = - (total + elem.setupPrice) * (1 - discount);

        planPrice      *= discount;
        roomPrice      *= discount;
        contactPrice   *= discount;
        addonPrice     *= discount;
        connectorPrice *= discount;
    }

    // Apply the commission
    planPrice      *= commission;
    roomPrice      *= commission;
    contactPrice   *= commission;
    addonPrice     *= commission;
    connectorPrice *= commission;
    totalPrice     *= commission;

    // Apply the days discount
    if (elem.isInitial) {
        elem.daysDiscountText = NLS.pluralize("SUBSCRIPTIONS_DAYS_DISCOUNT", elem.daysCharged);

        const date      = DateTime.create(elem.createdTime);
        const totalDays = DateTime.getMonthDays(date.month, date.year);
        const discount  = (elem.daysCharged * 100 / totalDays) / 100;
        daysPrice       = - total * (1 - discount);
    }

    // Calculate the final prices and values
    elem.amountText    = Plans.getAmountText(elem);
    elem.basePriceText = Plans.getBasePrice(elem.planType, {
        currencySymbol,
        roomPrice,
        planPrice,
        contactPrice,
    });

    elem.extraContacts       = Math.max(elem.usedContactAmount - elem.contactAmount, 0);

    elem.roomPriceText       = Commons.formatPrice(roomPrice, currencySymbol, 2);
    elem.roomTotalPriceText  = Commons.formatPrice(roomPrice * elem.roomAmount, currencySymbol);
    elem.planPriceText       = Commons.formatPrice(planPrice, currencySymbol);
    elem.contactPriceText    = Commons.formatPrice(contactPrice, currencySymbol, 2);
    elem.contactTotalText    = Commons.formatPrice(contactPrice * elem.extraContacts, currencySymbol);

    elem.addonPriceText      = Commons.formatPrice(addonPrice, elem.currencySymbol);
    elem.connectorPriceText  = Commons.formatPrice(connectorPrice, elem.currencySymbol);
    elem.setupPriceText      = Commons.formatPrice(elem.setupPrice, elem.currencySymbol);

    elem.discountPriceText   = Commons.formatPrice(discountPrice, elem.currencySymbol);
    elem.daysPriceText       = Commons.formatPrice(daysPrice, elem.currencySymbol);
    elem.totalPriceText      = Commons.formatPrice(totalPrice, elem.currencySymbol);

    elem.addons              = addons;
    elem.connectors          = connectors;
    elem.campaignText        = (elem.campaignDispatches || elem.campaignLimit) ? `${elem.campaignDispatches} / ${elem.campaignLimit}` : "";
    elem.integrationText     = (elem.integrationExecutions || elem.integrationLimit) ? `${elem.integrationExecutions} / ${elem.integrationLimit}` : "";
    elem.commissionText      = hasCommission ? `${elem.commission}%` : "";


    // Parse the WABAs
    if (elem.wabas) {
        for (const waba of elem.wabas) {
            waba.serviceText = `${waba.serviceConversations} / 1000`;
            waba.total       = waba.marketingConversations + waba.utilityConversations + waba.referralConversations + waba.serviceConversations;
        }
    }

    // Parse the Assistants
    if (elem.assistants) {
        for (const assistant of elem.assistants) {
            const inputPrice  = Commons.formatPrice(assistant.inputCost, "US$", 2);
            const outputPrice = Commons.formatPrice(assistant.outputCost, "US$", 2);

            assistant.name       = `${assistant.assistantName} (${assistant.model})`;
            assistant.inputText  = NLS.format("SUBSCRIPTIONS_TOKENS_COST", inputPrice, assistant.inputTokens);
            assistant.outputText = NLS.format("SUBSCRIPTIONS_TOKENS_COST", outputPrice, assistant.outputTokens);
            assistant.totalText  = Commons.formatPrice(assistant.inputCost + assistant.outputCost, "US$", 2);
        }
    }
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "SUBSCRIPTION_LIST", "SUBSCRIPTION_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "SUBSCRIPTION_LOADING":
        return {
            ...state,
            loading       : true,
        };

    case "SUBSCRIPTION_LIST":
        return {
            ...state,
            loading       : false,
            error         : false,
            canCreate     : action.data.canCreate,
            canEdit       : action.data.canEdit,
            canImport     : action.data.canImport,
            canExport     : action.data.canExport,
            canFilter     : action.data.canFilter,
            list          : Utils.parseList(action.data.list, (elem) => parseElem(elem, action.data.forAdmin)),
            total         : action.data.total,
            stats         : action.data.stats,
            filters       : action.data.filters,
            hasFilters    : !Utils.isEmpty(action.data.filters),
            sort          : action.data.sort,
        };

    case "SUBSCRIPTION_ELEM":
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            canEdit       : action.data.canEdit,
            elem          : parseElem(action.data.elem, action.data.forAdmin),
        };

    case "SUBSCRIPTION_FILTER":
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            partners      : action.data.partners,
            clients       : action.data.clients,
            idsPerPartner : action.data.idsPerPartner,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
