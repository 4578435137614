import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Commons              from "Utils/Commons";

// Components
import FlowEdit             from "../Dialogs/FlowEdit";
import FlowSimulationStart  from "../Simulation/FlowSimulationStart";

// Dashboard
import AlertDialog          from "Dashboard/Components/Dialogs/AlertDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Flow Dialogs
 * @returns {React.ReactElement}
 */
function FlowDialogs() {
    const { flowID, flowTab } = Navigate.useParams();
    const navigate = Commons.useGoto();

    const { openDetails } = Store.useAction("core");

    const { elem } = Store.useState("flow");
    const { fetchElem, publishFlow, clearFlow, deleteFlow } = Store.useAction("flow");

    const { publishErrors } = Store.useState("flowEditor");
    const { fetchEditor } = Store.useAction("flowEditor");

    const { action } = Store.useState("flowState");
    const { setAction, setInPublish, setDetailsTab } = Store.useAction("flowState");


    // Handles the Edit
    const handleEdit = (response) => {
        setAction();
        if (response.flowID !== elem.id) {
            navigate("SETTINGS", "FLOWS", response.flowID, "EDIT");
        } else {
            fetchElem(elem.id);
        }
    };

    // Handles the Simulate
    const handleSimulate = () => {
        setAction();
        if (!Navigate.isUrl("EDIT", flowTab)) {
            navigate("SETTINGS", "FLOWS", flowID, "EDIT");
        }
        setDetailsTab("simulation");
        openDetails();
    };

    // Handles the Publish
    const handlePublish = async () => {
        setAction();
        const result = await publishFlow(elem.id);
        if (result.success) {
            setInPublish(false);
            fetchEditor(elem.id);
        }
    };

    // Handles the Clear
    const handleClear = async () => {
        setAction();
        const result = await clearFlow(elem.id);
        if (result.success) {
            fetchEditor(elem.id);
        }
    };

    // Handles the Delete
    const handleDelete = async () => {
        setAction();
        const result = await deleteFlow(elem.id);
        if (result.success) {
            navigate("SETTINGS", "FLOWS");
        }
    };

    // Handles the Errors
    const handleErrors = () => {
        setAction();
        if (!Navigate.isUrl("EDIT", flowTab)) {
            navigate("SETTINGS", "FLOWS", flowID, "EDIT");
        } else {
            openDetails();
        }
        setDetailsTab("editor");
    };



    // Do the Render
    return <>
        <FlowEdit
            open={action.isEdit || action.isCopy}
            isCopy={action.isCopy}
            elemID={elem.id}
            clientID={elem.clientID}
            onSubmit={handleEdit}
            onClose={setAction}
        />
        <FlowSimulationStart
            open={action.isSimulate}
            onSubmit={handleSimulate}
            onClose={setAction}
        />

        <ConfirmDialog
            open={action.isPublish}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message="FLOWS_PUBLISH_CHANGES"
            content={elem.name}
            onSubmit={handlePublish}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isClear}
            icon="clear"
            title="GENERAL_CLEAR_CHANGES"
            message="FLOWS_CLEAR_CHANGES"
            primary="GENERAL_CLEAR"
            content={elem.name}
            onSubmit={handleClear}
            onClose={setAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="FLOWS_DELETE_TITLE"
            message="FLOWS_DELETE_TEXT"
            content={elem.name}
            onSubmit={handleDelete}
            onClose={setAction}
        />

        <AlertDialog
            open={action.isError}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message={publishErrors.length > 1 ? "FLOWS_ERRORS_TEXT" : "FLOWS_ERROR_TEXT"}
            content={String(publishErrors.length)}
            onClose={handleErrors}
        />
    </>;
}

export default FlowDialogs;
